import ApplicationController from './application_controller';

// Connects to data-controller="survey-delivery-day"
export default class extends ApplicationController {
  static targets = ['inputDays', 'inputTime', 'output'];

  static values = {
    url: String,
  };

  connect() {
    super.connect();
    this.next_delivery_day();
  }

  next_delivery_day() {
    const time = this.inputTimeTargets.map((el) => {
      const options = Array.from(el.options);
      return options.find((option) => option.selected).value;
    }).join(':');
    const url = decodeURI(this.urlValue);
    const urlWithParams = url.replace('%days%', this.inputDaysTarget.value).replace('%time%', `${time}:00`);

    fetch(encodeURI(urlWithParams))
      .then((response) => response.json())
      .then((json) => {
        this.outputTarget.innerText = json.message;
      });
  }
}
